<template>
    <div class="chat-window">
        <ChatWindowHeader :chat-data="chatData"/>
        <ClientLeave v-if="!chatData.online"/>
        <IdentificationSent v-if="chatData.online && !chatData.requestResolved && identificationSent" :chat-data="chatData" />
        <transition name="agent-enter" mode="out-in">
            <ChatIncoming v-if="incomingChat" class="chat-window__panel-fixed"/>
        </transition>
        <ChatWindowBody :messages="messages" ref="window" :no-avatar-key="chatData.chatRoom"
                        @phone-click="onMessageClick" @scroll="onScrollBody"/>
        <ChatWindowScrollButton :chat-data="chatData" :visible="!isScrollPinned" @scroll-down="scrollDown"/>
        <form v-if="chatData.online" class="chat-window__footer" @submit.prevent="sendMessage">
            <div class="chat-window__input-wrap">
                <div class="success-wrap success-wrap--radius-medium" :class="{'success-wrap--enabled': inputFocusable}">
                    <AppTextareaA :value="messageText" class="chat-window__input" :placeholder="$t('components.common.yourMessage')"
                                 :error="messageError" ref="input" @keydown="onInputKey" @input="onInput"/>
                </div>
                <div class="chat-window__input-controls">
                    <div v-if="false" class="chat-window__upload">
                        <button
                            v-if="!isAttachmentUploading"
                            class="chat-window__input-control"
                            type="button"
                            @click="onFileUploadClick"
                        >
                            <AppIcon icon="file-upload"/>
                        </button>
                        <Loader class="chat-attachment-spinner" v-else />
                        <input @change="onFileChange" ref="fileUploadInput" type="file" :accept="inputAcceptFileTypes">
                    </div>
                    <ChatTipsMenu :chat-data="chatData" @select="onInput" @close="focus"/>
                </div>
            </div>
            <AppButton round size="medium-extra" type="submit" v-tooltip="$t('components.common.sendAMessage')">
                <AppIcon icon="send"/>
            </AppButton>
        </form>
    </div>
</template>
<script>
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import ChatTipsMenu from "./ChatTipsMenu";
import ChatWindowBody from "./ChatWindowBody";
import ChatWindowHeader from "./window/ChatWindowHeader";
import ClientLeave from "./window/ClientLeave";
import IdentificationSent from "./window/IdentificationSent";
import ChatWindowScrollButton from "./window/ChatWindowScrollButton";
import chatMessagesService from "../../../services/chat/ChatMessagesService";
import chatWindowService from "../../../services/chat/ChatWindowService";
import ChatController from "@/services/ChatController";
import ChatIncoming from "./controls/ChatIncoming";
import {mapState} from "vuex";
import {ChatRoom} from "../../../services/chat/ChatModel";
import AppTextareaA from "../AppTextareaA";
import { post } from "../../../helpers/api"
import Loader from "../../Loader.vue"

export default {
    name: 'ChatWindow',
    components: {
        AppTextareaA,
        ChatIncoming,
        ChatWindowScrollButton,
        IdentificationSent,
        ClientLeave,
        ChatWindowHeader, ChatWindowBody, ChatTipsMenu, AppIcon, AppButton, Loader,
    },
    props: {
        chatData: ChatRoom,
    },
    data() {
        return {
            isScrolling: false,
            errorImages: [],

            inputFocusable: false,
            messageError: false,
            isScrollResetting: false,
            resizeObserver: null,
            isScrollPinned: false,

            oldMessagesProcessing: false,

            isAttachmentUploading: false
        }
    },
    computed: {
        ...mapState('chat', ['incomingChat', 'activeChatId']),
        userData() {
            return this.chatData.customers.find(c => c.id === this.chatData.selectedCustomer) || {};
        },
        usernameChanged() {
            return this.chatData.customerFieldsUpdated.includes('username') || this.chatData.customerFieldsUpdated.includes('is_abs_active');
        },
        messages() {
            return [...this.chatData.oldMessages, ...this.chatData.messages];
        },
        userTags() {
            const user = this.userData;
            if(!user.id) return [];
            const tags = [
                {
                    value: user.is_abs_active ? 'активный' : 'не активный',
                    theme: user.is_abs_active ? 'primary' : 'gray'
                }
            ];
            if(user.bank_client_id) {
                tags.unshift({
                    icon: 'checked',
                })
            }
            return tags;
        },
        identificationSent() {
            return this.chatData.requestSent;
        },
        messageText() {
            return this.chatData.messageInput;
        },
        savedScroll() {
            return this.chatData.savedScroll;
        },
        fileUploadInput() {
            return this.$refs.fileUploadInput
        },
        inputAcceptFileTypes() {
            return process.env.VUE_APP_CHAT_FILE_MIME_TYPE
        }
    },
    mounted() {
        this.resetScroll();
        this.isScrollPinned = this.$refs.window.messagesBody.scrollHeight - 100 <= this.$refs.window.messagesBody.offsetHeight;
        this.$nextTick(() => {
            this.resizeObserver = new ResizeObserver(() => {
                if(this.isScrollPinned) {
                    this.scrollDown(true);
                }
            });
            this.resizeObserver.observe(this.$refs.window.messagesBody);
        });
        this.focus();
    },
    activated() {
        this.resetScroll();
        this.$nextTick(() => {
            this.readMessages();
        });
        this.focus();
    },
    beforeDestroy() {
        this.resizeObserver.disconnect();
    },
    watch: {
        '$store.state.isChat'(value) {
            if(value) {
                setTimeout(() => {
                    this.readMessages();
                    this.focus();
                }, 500)
            }
        },
        'chatData.oldMessages'() {
            this.oldMessagesProcessing = true;
            const messagesBody = this.$refs.window.messagesBody;
            const delta = messagesBody.scrollHeight - this.chatData.savedScroll;

            this.$nextTick(() => {
                const newHeight = messagesBody.scrollHeight;
                messagesBody.scrollTop = newHeight - delta;
                this.oldMessagesProcessing = false;
            });
        },
        'chatData.messages'() {
            const messageBody = this.$refs.window.messagesBody;
            if(!this.$store.state.isChat || this.chatData.chatRoom !== this.activeChatId || !(messageBody && messageBody.offsetHeight)) {
                return;
            }
            this.$nextTick(() => {
                this.scrollIfNeed();
                this.readMessages();
            });
        },
    },
    methods: {
        onInput(e) {
            this.messageError = false;
            chatMessagesService.inputChatMessage(e, this.chatData);
        },
        onInputKey(e) {
            this.inputFocusable = false;
            if((e.code === 'Enter' || e.code === 'NumpadEnter') && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                this.sendMessage(e);
            }
        },
        scrollIfNeed() {
            if(this.isScrollPinned) {
                this.scrollDown(true);
            }
        },
        sendMessage(e) {
            this.inputFocusable = false;
            if(!this.messageText) {
                this.messageError = true;
                return;
            }
            e.stopPropagation();
            chatMessagesService.sendChatMessageFromData(this.chatData);
            this.focus();
            this.scrollDown(true);
        },

        resetScroll() {
            if(this.isScrollPinned) {
                this.scrollDown(true);
            } else {
                this.$refs.window.messagesBody.scrollTo(0, this.chatData.savedScroll);
            }
        },

        onMessageClick(phone) {
            chatWindowService.getCustomerByPhone(phone, this.chatData);
        },

        readMessages() {
            const mb = this.$refs.window.messagesBody;
            const noNeedToRead = mb.scrollHeight / 2 > mb.offsetHeight + this.chatData.savedScroll;

            if(!this.chatData.messages.length || !this.chatData.unreadMessages || noNeedToRead) return;

            const refs = this.$refs.window.messageWrappers;

            this.$nextTick(() => {
                const message = refs[refs.length - this.chatData.unreadMessages];
                if(!message) return;

                if(message.offsetTop + message.offsetHeight / 2 < mb.offsetHeight + this.chatData.savedScroll) {
                    chatWindowService.readMessage(this.chatData);
                    this.readMessages();
                }
            });
        },
        scrollDown(isFast) {
            if(this.isScrolling) return;
            this.isScrolling = true;

            this.$nextTick(() => {
                this.$refs.window.scrollDown(isFast)
                    .then(() => {
                        this.isScrolling = false;
                    })
                this.focus();
            })
        },
        focus() {
            if(this.$refs.input) {
                this.$refs.input.focus();
            }
        },
        onScrollBody(e) {
            const messagesBody = e.target;
            this.readMessages();
            if(e.target.scrollTop < 200) {
                this.loadMessages();
            }
            chatWindowService.saveScroll(messagesBody.scrollTop, this.chatData);
            this.isScrollPinned = messagesBody.scrollTop + e.target.offsetHeight > e.target.scrollHeight - 100;
        },
        loadMessages() {
            if(this.oldMessagesProcessing) return;
            chatMessagesService.fetchOldMessages(this.chatData);
        },

        onFileChange(e) {
            const files = e.target.files
            if (files.length > 0) {
                const file = files[0]
                if (file.size < process.env.VUE_APP_CHAT_FILE_SIZE) {
                    // upload file
                    const formData = new FormData()
                    formData.append("file", file)
                    this.isAttachmentUploading = true
                    post("/upload/chat/files", formData).then(response => {
                        if (response.data.success && response.data.data.url) {
                            ChatController.sendMessage({
                                chatRoom: this.chatData.chatRoom,
                                message: response.data.data.url
                            })
                        }
                        this.isAttachmentUploading = false
                    }).catch(error => {
                        console.error("Chat file upload error", error)
                        this.$store.commit("notifications/error", "Failed to upload attachment")
                        this.isAttachmentUploading = false
                    })
                } else {
                    // show error modal
                    this.$store.commit("notifications/error", "File size is too big")
                }
            }
        },

        onFileUploadClick() {
            const input = this.$refs.fileUploadInput
            if (input) {
                input.click()
            }
        }
    }
}
</script>
<style lang="scss">
@import '../../../assets/scss/variables';
.chat-user-avatar {
    $self: &;
    display: flex;
    position: relative;
    &__lang {
        cursor: pointer;
        position: absolute;
        left: 28px;
        top: 31px;
        padding: 5px;
        margin: -5px;
        img {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            object-fit: cover;
            display: block;
        }
    }
    &__image {
        width: 44px;
        height: 44px;
        border-radius: 100%;
        margin-right: 16px;
        object-fit: cover;
    }
    &__wrap {
        flex-grow: 1;
        width: 100px;
    }
    &__name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 3px;
        max-width: 100%;
    }
    &__subname {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-primary);
        font-family: $font-secondary;
    }
    &--offline {
        #{$self}__subname {
            color: var(--color-gray-light);
        }
    }
}

.chat-window__upload {
    input {
        display: none;
    }

    .chat-attachment-spinner {
        width: 20px;
        height: 20px;
    }
}
</style>
