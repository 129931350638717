<template>
    <div class="chat-window__header">
        <div
            class="chat-window__user chat-user-avatar"
            :class="{ 'chat-user-avatar--offline': !chatData.online }"
        >
            <div class="chat-user-avatar__lang" @click="changeLang">
                <img :src="langIcon" alt="flag" />
            </div>
            <img
                v-if="chatData.data.avatar"
                :src="chatData.data.avatar"
                alt=""
                class="chat-user-avatar__image"
            />
            <NoAvatar
                v-else
                :user-name="chatData.data.userName"
                :key-name="chatData.chatRoom"
                class="chat-user-avatar__image"
                size="medium"
            />
            <div class="chat-user-avatar__wrap">
                <div class="chat-user-avatar__name">
                    <BankUserTags
                        :class="{ 'user-tags-shaking': usernameChanged }"
                        :value="chatData.data.userName"
                        :user-data="userData"
                    />
                </div>
                <div class="chat-user-avatar__subname">
                    {{
                        chatData.online
                            ? $t("components.common.online")
                            : $t("components.common.offline")
                    }}
                </div>
            </div>
        </div>
        <div class="chat-window__controls">
            <IdentificationPhone v-if="chatData.online" :chat-data="chatData" />
            <AppButton
                v-if="isSendMobilePhoneButtonVisible"
                theme="white-outline"
                round
                @click="onSendMobileAppLink"
            >
                <AppIcon icon="mail" />
            </AppButton>
            <ChatWindowSettings :chat-data="chatData" />
        </div>
    </div>
</template>
<script>
import { ChatRoom } from "../../../../services/chat/ChatModel";
import NoAvatar from "../NoAvatar";
import BankUserTags from "../../../BankUserTags";
import IdentificationPhone from "./IdentificationPhone";
import ChatWindowSettings from "./ChatWindowSettings";
import AppIcon from "../../AppIcon";
import AppButton from "../../AppButton.vue";
import chatWindowService from "../../../../services/chat/ChatWindowService";
import { qqLang, ruLang, uzLang } from "../../../../config/lang";

export default {
    name: "ChatWindowHeader",
    components: {
        AppIcon,
        ChatWindowSettings,
        IdentificationPhone,
        BankUserTags,
        NoAvatar,
        AppButton,
    },
    props: {
        chatData: ChatRoom,
    },
    computed: {
        usernameChanged() {
            return (
                "username" in this.chatData.customerFieldsUpdated ||
                "is_abs_active" in this.chatData.customerFieldsUpdated
            );
        },
        langIcon() {
            switch (this.chatData.lang) {
                case "ru":
                    return ruLang;
                case "uz":
                    return uzLang;
                case "qq":
                    return qqLang;
            }
        },
        userData() {
            if (!this.chatData) return null;
            return this.chatData.customers.find(
                (c) => c.id === this.chatData.selectedCustomer
            );
        },
        isSendMobilePhoneButtonVisible() {
            return this.chatData?.selectedCustomer !== null;
        },
        call_number() {
            return (
                this.chatData.selectedPhone || this.chatData.data?.phone || ""
            );
        },
    },
    methods: {
        changeLang() {
            chatWindowService.nextLang(this.chatData);
        },
        onSendMobileAppLink() {
            const formData = {
                phone: this.call_number.match(/\d+/g).join(""),
                lang: this.chatData.lang || "uz",
                user_id: this.userData?.id,
                user_name: this.userData?.username,
            };
            post("/send-sms-app-install", formData)
                .then(({ data }) => {
                    if (data.success) {
                        this.$store.commit("notifications/add", {
                            type: "success",
                            message: "СМС отправлено",
                        });
                    } else {
                        this.$store.commit(
                            "notifications/error",
                            "Не удалось отправить смс"
                        );
                    }
                })
                .catch((e) => {
                    this.$store.commit(
                        "notifications/error",
                        "Не удалось отправить смс"
                    );
                });
        },
    },
};
</script>
